import React from "react";

import styles from "./GlobalFooter.module.scss";

/* eslint-disable sort-keys */
const footerNavigationsData = {
  primarySections: [
    {
      nonUiTitle: "About",
      type: "primary",
      heading: "About",
      links: [
        {
          fields: {
            nonUiTitle: "Corporate Membership",
            title: "Corporate Membership",
            url: "/join/corp?icmp=bottomnav-corporatemembership",
          },
        },
        {
          fields: {
            nonUiTitle: "Careers",
            title: "Careers",
            url: "https://careers.equinox.com?icmp=bottomnav-careers",
          },
        },
        {
          fields: {
            nonUiTitle: "Pilates Teacher Training",
            title: "Pilates Teacher Training",
            url: "/landing/pilates-teacher-training?icmp=bottomnav-pilatesteachertraining",
          },
        },
        {
          fields: {
            nonUiTitle: "Gift Card",
            title: "Gift Card",
            url: "/gift-card/purchase?icmp=bottomnav-giftcard",
          },
        },
        {
          fields: {
            nonUiTitle: "Contact Us",
            title: "Contact Us",
            url: "/landing/getintouch?icmp=bottomnav-contactus",
          },
        },
        {
          fields: {
            nonUiTitle: "Member Policies",
            target: "_blank",
            title: "Member Policies",
            url: "https://assets.ctfassets.net/drib7o8rcbyf/6Eu5AOPwexnrxofQwUKvZE/dda63a8ed76fab167c88881159b8f484/Equinox-Member-Policies-2024-August.pdf",
          },
        },
        {
          fields: {
            nonUiTitle: "U.K. Disclosures",
            title: "U.K. Disclosures",
            url: "https://assets.cdn-equinox.com/pdf/equinox_group_uk_tax_strategy_2024.pdf",
          },
        },
      ],
    },
  ],
  secondarySections: [
    {
      nonUiTitle: "Secondary Links",
      type: "secondary",
      heading: "Secondary Link",
      links: [
        {
          fields: {
            nonUiTitle: "Send Feedback",
            title: "Send Feedback ",
            url: "/feedback",
          },
        },
        {
          fields: {
            nonUiTitle: "Privacy Policy",
            title: "Privacy Policy",
            url: "/privacy",
          },
        },
        {
          fields: {
            nonUiTitle: "California Consumer Privacy Statement",
            title: "California Consumer Privacy Statement",
            url: "/privacy#YOUR_CALIFORNIA_PRIVACY",
          },
        },
        {
          fields: {
            nonUiTitle: "Do Not Sell or Share My Personal Information",
            title: "Do Not Sell or Share My Personal Information",
            url: "/dnsrequest",
          },
        },
        {
          fields: {
            nonUiTitle: "Terms & Conditions",
            title: "Terms & Conditions",
            url: "/terms",
          },
        },
        {
          fields: {
            nonUiTitle: "FAQs",
            title: "FAQs",
            url: "/faq",
          },
        },
      ],
    },
  ],
  socialSections: [
    {
      nonUiTitle: "Social",
      type: "social",
      heading: "Social",
      links: [
        {
          fields: {
            nonUiTitle: "Facebook",
            title: "Facebook",
            url: "https://www.facebook.com/Equinox",
            icon: {
              fields: {
                title: "facebook-1",
                description: "this is image",
                file: {
                  url: "//images.ctfassets.net/drib7o8rcbyf/7pbnYy876X4wHpmw2K2JtL/1dce1400f81a986c654e6dd0982c4273/facebook-1.jpg",
                  details: {
                    size: 2069,
                    image: {
                      width: 167,
                      height: 167,
                    },
                  },
                  fileName: "facebook-1.jpg",
                  contentType: "image/jpeg",
                },
              },
            },
          },
        },
        {
          fields: {
            nonUiTitle: "X",
            title: "X",
            url: "https://x.com/Equinox",
            icon: {
              fields: {
                title: "X-icon",
                description:
                  "social icon for redirection to equinox social network accounts",
                file: {
                  url: "//images.ctfassets.net/drib7o8rcbyf/5g2Xaa8VVzFc5Spl6kcLO1/62b5bddf029e5f495f2ecc8e896dbb30/twitter.jpg",
                  details: {
                    size: 2430,
                    image: {
                      width: 167,
                      height: 167,
                    },
                  },
                  fileName: "twitter.jpg",
                  contentType: "image/jpeg",
                },
              },
            },
          },
        },
        {
          fields: {
            nonUiTitle: "Youtube",
            title: "Youtube",
            url: "https://www.youtube.com/user/equinox",
            icon: {
              fields: {
                title: "Youtube-icon",
                description:
                  "social icon for redirection to equinox social network accounts",
                file: {
                  url: "//images.ctfassets.net/drib7o8rcbyf/Pu8s9vVMV0D4JwPD05mqD/dd3a18c7a0c2fd042ac2f83eec3a8bfa/youtube.jpg",
                  details: {
                    size: 3220,
                    image: {
                      width: 167,
                      height: 167,
                    },
                  },
                  fileName: "youtube.jpg",
                  contentType: "image/jpeg",
                },
              },
            },
          },
        },
        {
          fields: {
            nonUiTitle: "Instagram",
            title: "Instagram",
            url: "https://www.instagram.com/equinox/",
            icon: {
              fields: {
                title: "Instagram-icon",
                description:
                  "social icon for redirection to equinox social network accounts",
                file: {
                  url: "//images.ctfassets.net/drib7o8rcbyf/rqvurGiOopoDMKtPn1ciX/2ad5cd42c63cbab3de93d1aafe4786b5/instagram.jpg",
                  details: {
                    size: 3313,
                    image: {
                      width: 167,
                      height: 167,
                    },
                  },
                  fileName: "instagram.jpg",
                  contentType: "image/jpeg",
                },
              },
            },
          },
        },
      ],
    },
  ],
};
/* eslint-enable sort-keys */

const GlobalFooter = () => {
  return (
    <footer
      className={styles.globalFooter}
      data-is="GlobalFooter"
      id="global-footer"
    >
      <div className={styles.content}>
        <nav aria-label="Links About Equinox" className={styles.aboutLinks}>
          {footerNavigationsData?.primarySections[0]?.links?.map(
            ({ fields }) => (
              <a
                className={styles.navLink}
                href={fields?.url}
                key={fields?.title}
                rel={
                  fields?.target === "_blank"
                    ? "noopener noreferrer"
                    : undefined
                }
                target={fields?.target}
                title={fields?.title}
              >
                {fields?.title}
              </a>
            )
          )}
        </nav>

        <nav
          aria-label="Equinox Social Media Presences"
          className={styles.socialLinks}
        >
          {footerNavigationsData?.socialSections[0]?.links?.map(
            ({ fields }) => (
              <a
                className={styles.socialIcon}
                href={fields?.url}
                key={fields?.title}
                rel="noopener noreferrer"
                style={{
                  backgroundImage: `url(https:${fields?.icon?.fields?.file?.url})`,
                }}
                target="_blank"
                title={fields?.title}
              >
                {fields?.title}
              </a>
            )
          )}
        </nav>

        <nav aria-label="Legalese and Help Links" className={styles.legalLinks}>
          {footerNavigationsData?.secondarySections[0]?.links?.map(
            ({ fields }) => (
              <a
                className={styles.navLink}
                href={fields?.url}
                key={fields?.title}
                title={fields?.title}
              >
                {fields?.title}
              </a>
            )
          )}

          {process.env.NEXT_PUBLIC_ONETRUST_DOMAIN_ID && (
            <button
              className={`${styles.navLink} ot-sdk-show-settings`}
              name="GlobalFooter - Cookie Settings"
              title="Cookie Settings"
            >
              Cookie Settings
            </button>
          )}
        </nav>
      </div>
    </footer>
  );
};

export default GlobalFooter;
